<template>
    <section class="legal-notice-container">
        <h1>CGV</h1>

        <div class="legal-notice-text-container">
            <div class="text-wrapper">
                <p class="subtitle">Article 1 – Intégralité</p>
                <p>1.1</p>
                <p>
                    Les présentes conditions générales expriment l'intégralité
                    des obligations des parties. En ce sens, l'acheteur est
                    réputé les accepter sans réserve.
                </p>
                <p>
                    Le vendeur et l'acheteur conviennent que les présentes
                    conditions générales régissent exclusivement leur relation.
                    Le vendeur se réserve le droit de modifier ponctuellement
                    ses conditions générales. Elles seront applicables dès leur
                    mise en ligne.
                </p>
                <p>
                    Si une condition de vente venait à faire défaut, elle serait
                    considérée être régie par les usages en vigueur dans le
                    secteur de la vente à distance dont les sociétés ont siégé
                    en France.
                </p>
            </div>
            <div class="text-wrapper">
                <p class="subtitle">Article 2 – Objet</p>
                <p>2.1</p>
                <p>
                    Les présentes conditions générales ont pour objet de définir
                    les droits et obligations des parties dans le cadre de la
                    vente en ligne des services proposés par le vendeur à
                    l'acheteur.
                </p>
                <p>
                    Les présentes conditions ne concernent que les achats
                    effectués par les acheteurs situés en France.
                </p>
                <p>
                    En conséquence, l'accès et l'utilisation du site
                    lecoinduprof.fr est subordonné des conditions générales de
                    vente objet des présentes.
                </p>
            </div>
            <div class="text-wrapper">
                <p class="subtitle">Article 3 – La commande</p>
                <p>3.1</p>
                <p>
                    L'acheteur a la possibilité de passer sa commande en ligne,
                    à partir du catalogue en ligne et au moyen du formulaire qui
                    y figure.
                </p>
                <p>
                    Pour que la commande soit validée, l'acheteur devra
                    accepter, en cliquant à l'endroit indiqué, les présentes
                    conditions générales et enfin valider le mode de paiement.
                </p>
                <p>
                    Toute commande vaut acceptation des prix et des descriptions
                    des produits disponibles à la vente. Toute contestation sur
                    ce point interviendra dans le cadre des garanties ci-dessous
                    mentionnées.
                </p>
                <p>
                    Dans certains cas, notamment défaut de paiement, adresse
                    erronée ou autre problème sur le compte de l'acheteur, le
                    vendeur se réserve le droit de bloquer la commande de
                    l'acheteur jusqu'à la résolution du problème.
                </p>
                <p>3.2</p>
                <p>
                    Pour toute question relative au suivi d'une commande,
                    l'acheteur devra adresser un courriel à l'adresse suivante :
                    <a href="mailto:contact@lecoinduprof.fr">
                        contact@lecoinduprof.fr</a>
                </p>
            </div>
            <div class="text-wrapper">
                <p class="subtitle">Article 4 – Signature électronique</p>
                <p>4.1</p>
                <p>
                    La fourniture en ligne du numéro de carte bancaire de
                    l'acheteur et la validation finale de la commande vaudront
                    preuve de l'accord de l'acheteur conformément aux
                    dispositions de la loi du 13 mars 2000 et vaudront :
                </p>
                <p>
                    – exigibilité des sommes dues au titre du bon de commande,
                </p>
                <p>
                    – signature et acception expresse de toutes les opérations
                    effectuées.
                </p>
                <p>4.2</p>
                <p>
                    En cas d'utilisation frauduleuse de la carte bancaire,
                    l'acheteur est invité, dès le constat de cette utilisation,
                    à contacter par courriel à l'adresse suivante :
                    contact@lecoinduprof.fr
                </p>
            </div>
            <div class="text-wrapper">
                <p class="subtitle">Article 5 – Confirmation de commande</p>
                <p>5.1</p>
                <p>
                    Les informations contractuelles feront l'objet d'une
                    confirmation par voie d'e-mail au plus tard au moment de la
                    livraison (voir article 10.1 – délai de livraison).
                </p>
                <p>
                    Une fois la commande validée, l'acheteur reçoit
                    immédiatement un e-mail de confirmation contenant les
                    éléments essentiels de sa commande, soit, les liens de
                    téléchargement des produits.
                </p>
            </div>
            <div class="text-wrapper">
                <p class="subtitle">Article 6 – Preuve de la transaction</p>
                <p>6.1</p>
                <p>
                    Les registres informatisés, conservés dans les systèmes
                    informatiques du vendeur dans des conditions raisonnables de
                    sécurité, seront considérés comme les preuves des
                    communications, des commandes et des paiements intervenus
                    entre les parties. L'archivage des bons de commande et des
                    factures est effectué sur un support fiable et durable
                    pouvant être produit à titre de preuve.
                </p>
            </div>
            <div class="text-wrapper">
                <p class="subtitle">
                    Article 7 – Informations sur les produits
                </p>
                <p>7.1</p>
                <p>
                    Les produits régis par les présentes conditions générales
                    sont ceux qui figurent sur le site internet du vendeur et
                    qui sont indiqués comme vendus en ligne par le vendeur.
                </p>
                <p>
                    COLOR THE EDUCATION est présenté avec la plus grande exactitude
                    possible. Toutefois, si des erreurs ou omissions ont pu se
                    produire quant à cette présentation, la responsabilité du
                    vendeur ne pourrait être engagée.
                </p>
                <p>7.2</p>
                <p>Limite d'usage des produits :</p>
                <p>
                    Il est interdit de dupliquer les données électroniques ou
                    matérielles (fichiers PDF, PPT) fournies par
                    lecoinduprof.fr. De fait, la ressource ne pourra être
                    utilisé que par la personne ou l'établissement scolaire
                    ayant fait son acquisition. Les ressources ne peuvent être
                    utilisées dans le cadre d'une activité de service
                    (organisation d'évènements, …) sans l'accord express de COLOR THE EDUCATION. Les ressources ne
                    peuvent être intégrées dans une
                    offre commerciale groupée (Bundle, pack de jeux, …) sans
                    l'accord express de COLOR THE EDUCATION. Les ressources ne peuvent
                    être revendus par un tiers sans l'accord express de COLOR THE EDUCATION.
                </p>
                <p>
                    Une licence d'utilisation attachée à chaque ressource de
                    COLOR THE EDUCATION est concédée aux clients du site
                    www.lecoinduprof.fr.
                </p>
            </div>
            <div class="text-wrapper">
                <p class="subtitle">Article 8 – Prix</p>
                <p>8.1</p>
                <p>
                    Le vendeur se réserve le droit de modifier ses prix à tout
                    moment, mais s'engage à appliquer les tarifs en vigueur
                    indiqués au moment de la commande.
                </p>
                <p>
                    Les prix sont indiqués en euros et tiennent compte de la TVA
                    applicable au jour de la commande et tout changement du taux
                    applicable TVA sera automatiquement répercuté sur le prix
                    des produits de la boutique en ligne. Le paiement de la
                    totalité du prix doit être réalisé lors de la commande. À
                    aucun moment, les sommes versées ne pourront être
                    considérées comme des arrhes ou des acomptes.
                </p>
            </div>
            <div class="text-wrapper">
                <p class="subtitle">Article 9 – Mode de paiement</p>
                <p>9.1</p>
                <p>
                    Pour régler sa commande, l'acheteur dispose, à son choix, de
                    l'ensemble des modes de paiement mis à sa disposition par le
                    vendeur et des listes sur le site du vendeur. L'acheteur
                    garantit au vendeur qu'il dispose des autorisations
                    éventuellement nécessaires pour utiliser le mode de paiement
                    choisi par lui, lors de la validation du bon de commande. Le
                    vendeur se réserve le droit de suspendre toute gestion de
                    commande en cas de refus d'autorisation de paiement par
                    carte bancaire de la part des organismes officiellement
                    accrédités ou en cas de non-paiement. Le vendeur se réserve
                    notamment le droit de refuser d'effectuer, d'honorer une
                    commande émanant d'un acheteur qui n'aurait pas réglé
                    totalement ou partiellement une commande précédente ou avec
                    lequel un litige de paiement serait en cours
                    d'administration.
                </p>
            </div>
            <div class="text-wrapper">
                <p class="subtitle">
                    Article 10 – Modalités de livraison et validité des produits
                </p>
                <p>10.1</p>
                <p>
                    À réception du paiement, la livraison se fait immédiatement
                    en ligne via email ou directement depuis l'espace personnel
                    de l'acheteur sur le site www.lecoinduprof.fr
                </p>
                <p>
                    L'utilisateur télécharge les produits achetés sur son
                    ordinateur, en cliquant sur les liens présents dans l'email
                    ou son espace personnel.
                </p>
                <p>10.2</p>
                <p>
                    Pour tout achat effectué après le 1er février 2023, les
                    liens de téléchargement des produits ont un délai
                    d'expiration à compter de la date d'achat, renseigné sur la
                    page de téléchargement du produit en question. Passé ce
                    délai, l'Utilisateur ne sera plus en mesure de demander à COLOR THE EDUCATION de nouveaux liens de
                    téléchargement, quel que
                    soit le motif.
                </p>
                <p>
                    Il sera alors possible de commander à nouveau le produit
                    pour disposer d'un nouveau droit d'utilisation des
                    ressources durant le délai indiqué.
                </p>
            </div>
            <div class="text-wrapper">
                <p class="subtitle">Article 11 – Garantie des produits</p>
                <p>11.1</p>
                <p>
                    Conformément aux textes légaux en vigueur, le vendeur
                    garantit l'acheteur contre toutes les conséquences des vices
                    cachés de la chose vendue et la mise à jour des données
                    juridiques y figurant.
                </p>
            </div>
            <div class="text-wrapper">
                <p class="subtitle">Article 12 – Non-validation partielle</p>
                <p>12.1</p>
                <p>
                    Si une ou plusieurs stipulations des présentes conditions
                    générales sont tenues pour non valides ou déclarées telles
                    en application d'une loi, d'un règlement ou à la suite d'une
                    décision définitive d'une juridiction compétente, les autres
                    stipulations garderont toute leur force et leur portée.
                </p>
            </div>
            <div class="text-wrapper">
                <p class="subtitle">Article 13 – Non-renonciation</p>
                <p>13.1</p>
                <p>
                    Le fait pour l'une des parties de ne pas se prévaloir d'un
                    manquement par l'autre partie à l'une quelconque des
                    obligations visées dans les présentes conditions générales
                    ne saurait être interprété pour l'avenir comme une
                    renonciation à l'obligation en cause.
                </p>
            </div>
            <div class="text-wrapper">
                <p class="subtitle">Article 14 – Loi applicable</p>
                <p>
                    Les présentes conditions générales sont soumises à
                    l'application du droit français. Il en est ainsi pour les
                    règles de fond comme pour les règles de forme. En cas de
                    litige ou de réclamation, l'acheteur s'adressera en priorité
                    au vendeur pour obtenir une solution amiable.
                </p>
                <p>
                    Courriel :
                    <a href="mailto:contact@lecoinduprof.fr">
                        contact@lecoinduprof.fr</a>
                </p>
                <p>
                    Site internet :
                    <a href="https://www.lecoinduprof.fr/">
                        www.lecoinduprof.fr</a>
                </p>
                <p>Aucune copie et revente des documents n'est autorisée.</p>
            </div>
        </div>
    </section>
</template>

<script>

</script>

<style lang="scss" scoped>
@import '../assets/scss/legal-notice.scss';
</style>
